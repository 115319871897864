import React from 'react';
import defaultImage from '../../../../../assets/img/avatar/default-picture.png';
import { BoxColorIcon } from '../Icons';

export function AvatarView ({ image, width = 40, height = 40 }) {
  return (
    <>
      {image
        ? (
        <div
          className={'rounded-full overflow-hidden'}
          style={{ width, height }}
        >
          <img
            src={image}
            alt="Your Image"
            className="w-full h-full object-cover "
          />
        </div>
          )
        : (
        <div
          className={'rounded-full overflow-hidden'}
          style={{ width, height }}
        >
          <img
            src={defaultImage}
            alt="Your Image"
            className="w-full h-full object-cover bg-[#a4acbc]"
          />
        </div>
          )}
    </>
  );
}

export function AvatarProduct ({ image, width = 40, height = 40 }) {
  return (
    <>
      {image
        ? (
        <div
          className={'rounded-full overflow-hidden'}
          style={{ width, height }}
        >
          <img
            src={image}
            alt="Your Image"
            className="w-full h-full object-cover "
          />
        </div>
          )
        : (
        <BoxColorIcon />
          )}
    </>
  );
}
