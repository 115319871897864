import React from 'react';
// import { checkAuthentication, checkRoleId } from 'utils/authUtils';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ allowedRoles }) => {
  // const isAuthenticated = checkAuthentication();
  // const roleId = +checkRoleId();
  // const isAuthorized = isAuthenticated && allowedRoles === roleId;

  const isAuthorized = true;

  return isAuthorized ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
