import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Konfigurasi Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyDoejUQ6JR84D8Od0W-tBLlYURoi7mU27A',
  authDomain: 'talasi-app.firebaseapp.com',
  projectId: 'talasi-app',
  storageBucket: 'talasi-app.appspot.com',
  messagingSenderId: '825180481499',
  appId: '1:825180481499:web:02b01c601d7adbcc01a9fe',
  measurementId: 'G-61KXE15VQG'
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

// Initialize Firebase
initializeApp(firebaseConfig);

// Method yg digunakan untuk mendapatkan token
export const getTokenFirebase = () => {
  return getToken(messaging, { vapidKey: 'BEynjAcds1p6vzcEsRh6ZtaQv_awun7Kqzf7RfiiLA_8Rxls_vCYJO1bAomiecf6FJA1D-LgdvsoOs1E6F_DW9U' })
    .then((currentToken) => {
      return currentToken;
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
