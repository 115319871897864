import React, { useState, useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { useMutation, useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { NavbarPanel } from 'react-web-skeleton/dist/components/Navbar';

import {
  Dropdown,
  ItemListDropdown,
  Icon,
  Text,
  Hint,
  List,
  ItemList,
  Loading,
} from 'react-web-skeleton';
import {
  getNotifications,
  readNotifications,
} from 'utils/common/notifications';

import { checkRoleId, clearLocalStorageData } from 'utils/authUtils';
import SocketVoiceCall from 'helper/SocketVoiceCall';
import { objectToParams } from 'helper/globalFunction';

import { CallIcon } from 'components/UI/common/atoms/Icons';
import { AvatarView } from 'components/UI/common/atoms/AvatarView';
import ToastCall from 'components/UI/purchasing/molecules/ToastCall';
import ModalCall from 'components/UI/purchasing/molecules/ModalCall';
import PaginationNotifications from 'components/UI/common/molecules/PaginationNotifications';

function TimeAgo ({ timestamp }) {
  if (!timestamp) return;
  const timeAgo = formatDistanceToNow(new Date(timestamp), { addSuffix: true });

  return <span>{timeAgo}</span>;
}

const Navbar = () => {
  const navigate = useNavigate();
  const roleId = parseInt(checkRoleId());
  const name = localStorage.getItem('name') ? localStorage.getItem('name') : '';
  const roleName = localStorage.getItem('role_name')
    ? localStorage.getItem('role_name')
    : '';

  const showToast = true;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: '',
  });

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    let timer;

    if (isModalOpen) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);

        if (seconds === 59) {
          setSeconds(0);
          setMinutes((prevMinutes) => prevMinutes + 1);
        }
      }, 1000);
    } else {
      clearInterval(timer);
      setSeconds(0);
      setMinutes(0);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isModalOpen, seconds]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    window.location.reload();
  };

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['fetchNotifications', filter],
    () => getNotifications(objectToParams(filter))
  );

  const { mutate } = useMutation(readNotifications);
  const detailNotification = (id, roleId, read, contentId, contentType) => {
    if (read === 'N') {
      mutate(id, {
        onSuccess: () => {
          refetch();
          if (Array.isArray(roleId)) {
            if (roleId.includes(1)) {
              // Admin
              // window.location.href = `admin`;
            }
            if (roleId.includes(2)) {
              // Finance
              window.location.href = `/penawaran/finance/update-status/${contentId}`;
            }
            if (roleId.includes(3)) {
              // Purchasing
              window.location.href = `/penawaran/purchasing/ubah/${contentId}`;
            }
            if (roleId.includes(4)) {
              // CFO
              if (
                contentType === 'OFFER_WITHOUT_SURVEY' ||
                contentType === 'OFFER_FINISHED_RECEIVING'
              ) {
                window.location.href = `/persetujuan/cfo/penawaran/${contentId}`;
              }

              if (contentType === 'PROPOSED_SPECIAL_PRICE') {
                window.location.href = `/persetujuan/cfo/harga-khusus/detail/${contentId}`;
              }

              if (contentType === 'PROPOSED_MARKET_PRICE') {
                window.location.href = `/persetujuan/cfo/harga-pasar/detail/${contentId}`;
              }
            }
          }
        },
      });
    }

    if (read === 'Y') {
      if (Array.isArray(roleId)) {
        if (roleId.includes(1)) {
          // Admin
          // window.location.href = `admin`;
        }
        if (roleId.includes(2)) {
          // Finance
          window.location.href = `/penawaran/finance/update-status/${contentId}`;
        }
        if (roleId.includes(3)) {
          // Purchasing
          window.location.href = `/penawaran/purchasing/ubah/${contentId}`;
        }
        if (roleId.includes(4)) {
          // CFO
          if (
            contentType === 'OFFER_WITHOUT_SURVEY' ||
            contentType === 'OFFER_FINISHED_RECEIVING'
          ) {
            window.location.href = `/persetujuan/cfo/penawaran/${contentId}`;
          }

          if (contentType === 'PROPOSED_SPECIAL_PRICE') {
            window.location.href = `/persetujuan/cfo/harga-khusus/detail/${contentId}`;
          }

          if (contentType === 'PROPOSED_MARKET_PRICE') {
            window.location.href = `/persetujuan/cfo/harga-pasar/detail/${contentId}`;
          }
        }
      }
    }
  };

  const handleLogout = () => {
    clearLocalStorageData();
    navigate('/login');
  };

  // voice call
  const {
    labelStatus,
    isThereIsIncomingCall,
    dataUSerCall,

    answerCall,
    rejectCall,
    endCall,
  } = SocketVoiceCall();

  return (
    <>
      <NavbarPanel>
        <div className="flex wrapper-menu-user gap-x-8">
          <Dropdown
            className="absolute right-28 flex-col max-w-sm scroll-m-0"
            elementAction={
              <Icon
                iconOnly
                label="bell"
                number={data?.unread_message > 0 ? data?.unread_message : null}
                size="small"
              />
            }
          >
            <div class="h-96 overflow-y-auto">
              <ItemListDropdown className="title text-center">
                <Text size="xl" weight="semibold">
                  Notifikasi
                </Text>
              </ItemListDropdown>
              {isLoading ||
                (isFetching && (
                  <div
                    style={{ width: '24rem' }}
                    className={`flex items-center justify-center transition-all duration-500 ${
                      isLoading || isFetching ? 'opacity-100' : 'opacity-0'
                    }`}
                  >
                    <Loading size="xl" />
                  </div>
                ))}
              {data && data?.data?.length > 0
                ? (
                    data?.data?.map((list, index) => {
                      return (
                    <ItemListDropdown
                      className={`notif-item ${
                        list.is_read_flag === 'N'
                          ? 'bg-slate-400'
                          : 'bg-slate-200'
                      }  text-left transition-all duration-500 ${
                        isLoading || isFetching ? 'opacity-0' : 'opacity-100'
                      }`}
                      key={index}
                      onClick={() => {
                        detailNotification(
                          list.id,
                          list.role_ids,
                          list.is_read_flag,
                          list.content_id,
                          list.content_type
                        );
                      }}
                    >
                      <div className="flex flex-row cursor-pointer">
                        <Icon
                          label="box"
                          mode="primary"
                          shape="square"
                          size="medium"
                          type="default"
                        />
                        <div
                          className="flex flex-column"
                          style={{
                            marginLeft: '8px',
                          }}
                        >
                          <Text size="l" weight="medium">
                            {list.description}
                          </Text>
                          <Text size="m" weight="regular">
                            <TimeAgo timestamp={list?.created_at || ''} />
                          </Text>
                        </div>
                      </div>
                    </ItemListDropdown>
                      );
                    })
                  )
                : (
                <div className="mx-5">
                  <Text size="m" weight="semibold">
                    Tidak ada notifikasi
                  </Text>
                </div>
                  )}
              <div className="mt-20" style={{ width: '24rem' }}>
                <div className="absolute bottom-0 left-0 w-full bg-gray-100 py-4 px-6 flex justify-between items-center">
                  {/* Pagination controls */}
                  <PaginationNotifications
                    filter={filter}
                    totalItems={data?.meta?.last_page}
                    setFilter={setFilter}
                  />
                </div>
              </div>
            </div>
          </Dropdown>

          <Dropdown
            // className="min-w-[50px]"
            elementAction={
              <>
                <List className="text-left cursor-pointer">
                  <ItemList>
                    <Text size="m" weight="semibold">
                      {name}
                    </Text>
                    <Hint size="m" weight="regular">
                      {roleName}
                    </Hint>
                  </ItemList>
                </List>
              </>
            }
          >
            <ItemListDropdown
              className={`notif-item w-[8rem] bg-white rounded-sm shadow-md border absolute ${
                name.length < 10 ? 'right-[-3.5rem]' : ''
              }`}
            >
              <List className="text-left">
                <ItemList>
                  <Text size="s" weight="semibold">
                    {name}
                  </Text>
                  <Hint size="s" weight="regular">
                    {roleName}
                  </Hint>
                </ItemList>
              </List>
              <List className="text-left my-4 cursor-pointer">
                <ItemList>
                  <Text size="s" weight="semibold">
                    {roleId === 2
                      ? (
                      <Link to={'/administrasi/finance/pengaturan/profil'}>
                        Pengaturan
                      </Link>
                        )
                      : roleId === 4
                        ? (
                      <Link to={'/pengaturan/cfo/profil'}>Pengaturan</Link>
                          )
                        : roleId === 3
                          ? (
                      <Link to={'/pengaturan/purchasing/profil'}>
                        Pengaturan
                      </Link>
                            )
                          : (
                      <Link to={'/pengaturan/admin/profil'}>Pengaturan</Link>
                            )}
                  </Text>
                </ItemList>
              </List>
              <List className="text-left cursor-pointer text-red-700">
                <ItemList>
                  <Text size="s" weight="semibold" onClick={handleLogout}>
                    Keluar
                  </Text>
                </ItemList>
              </List>
            </ItemListDropdown>
          </Dropdown>
        </div>
      </NavbarPanel>

      {roleId === 3 && (
        <>
          <div
            className={`${
              showToast
                ? 'opacity-100 translate-y-0'
                : 'opacity-0 -translate-y-5'
            } transition-transform duration-500 ease-in-out absolute top-0 right-0 p-4`}
          >
            <div className="shadow bg-white p-4 rounded-xl">
              {isThereIsIncomingCall && (
                <ToastCall
                  receiveCall={openModal}
                  answerCall={answerCall}
                  rejectCall={rejectCall}
                  dataUSerCall={dataUSerCall}
                />
              )}
            </div>
          </div>
          <div>
            <ModalCall isOpen={isModalOpen} onClose={closeModal}>
              <div className="text-center">
                <div className="rounded-full w-32 h-32 mx-auto mb-4">
                  <AvatarView
                    image={dataUSerCall.from_user_photo}
                    width={128}
                    height={128}
                  />
                </div>
                {labelStatus !== 'Panggilan Diakhiri' && (
                  <p className="text-xs ">{labelStatus}</p>
                )}
                <p className="text-xl font-bold">
                  {dataUSerCall.call_from_name}
                </p>

                {labelStatus !== 'Panggilan Diakhiri' && (
                  <p className="text-sm mt-3">
                    {minutes.toString().padStart(2, '0')}:
                    {seconds.toString().padStart(2, '0')}
                  </p>
                )}
                <button
                  className="bg-red-500 hover:bg-red-600 text-white rounded-full w-16 h-16 mt-20 relative"
                  onClick={() => {
                    endCall();
                    closeModal();
                  }}
                >
                  <span className="absolute inset-0 flex items-center justify-center">
                    <CallIcon />
                  </span>
                </button>
                {labelStatus === 'Panggilan Diakhiri' && (
                  <p className="text-2xl font-semibold mt-3 ">
                    Panggilan diakhiri
                  </p>
                )}
              </div>
            </ModalCall>
          </div>
        </>
      )}
    </>
  );
};

export default Navbar;
