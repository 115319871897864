import { useState, useEffect } from 'react';
import { checkAuthentication } from 'utils/authUtils';
import { socketCall } from 'helper/socket';
import AgoraRTC from 'agora-rtc-sdk-ng';

const SocketVoiceCall = () => {
  // configuration for websocket
  const token = checkAuthentication();
  const [callToID, setCallToID] = useState(0);
  const [callFromID, setCallFromID] = useState(0);
  const [rtcToken, setRtcToken] = useState('');
  const [isConnected, setIsConnected] = useState(socketCall.connected);
  const [labelStatus, setLabelStatus] = useState('Panggilan Masuk');
  const [isThereIsIncomingCall, setIsThereIsIncomingCall] = useState(false);
  const [dataUSerCall, setDataUserCall] = useState('');

  // configuration for agora
  const agoraAppID = process.env.REACT_APP_AGORA_APP_ID;
  const agoraEngine = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp9' });
  const [agoraOptions, setAgoraOptions] = useState({ appId: agoraAppID });
  const [agoraChannelParams, setAgoraChannelParams] = useState({});
  const [callStatus, setCallStatus] = useState('');
  const [uid, setUid] = useState('');

  // digunakan untuk init koneksi
  useEffect(() => {
    if (token !== '') {
      socketCall.on('connect', () => {
        socketCall.emit('joinMyVoiceCallRoom', { current_user_token: token });
      });

      socketCall.on('disconnect', () => {
        setIsConnected(false);
      });

      socketCall.on('joinMyVoiceCallRoom', (msg) => {
        if (msg === 'success') {
          setIsConnected(true);
        }
      });

      socketCall.on('connect_error', (err) => {
        console.log(`websocket connect_error due to ${err.message}`);
      });
    }
  }, [token]);

  // listen event dari server
  useEffect(() => {
    socketCall.on('joinVoiceCall', (msg) => {
      // console.log('msg.status', msg.status);
      const setAgoraObjects = async (rtcToken, channelName) => {
        setAgoraOptions((opts) => ({
          ...opts,
          channel: channelName,
          token: rtcToken,
        }));
      };

      if (msg.status === 'INCOMING_CALL') {
        setLabelStatus('Incoming call from ' + msg.call_from_name);
        setIsThereIsIncomingCall(true);
        setDataUserCall(msg);
        setAgoraObjects(msg.rtc_token, msg.channel_name);

        // set id from sender and receiver
        setCallFromID(msg.call_from_id);
        setCallToID(msg.call_to_id);
        setRtcToken(msg.rtc_token);

        // payload to send this user status
        // if this user receive this message with this status
        // this means this user already online
        socketCall.emit('incomingCallAction', {
          rtc_token: msg.rtc_token,
          call_from: msg.call_from_id,
          call_to: msg.call_to_id,
          action: 'RINGING', // constant status (all uppercase)
        });
      } else {
        setLabelStatus('error: ' + msg.status);
      }
    });

    socketCall.on('incomingCallAction', function (msg) {
      let targetUserAction = '';

      if (msg.action === 'ANSWERED') {
        targetUserAction = 'Panggilan Masuk';
        setCallStatus('ANSWER_CALL');
      } else if (msg.action === 'NOT_ANSWERED') {
        // reset status
        setCallFromID(0);
        setCallToID(0);
        setRtcToken('');
        setCallStatus('LEAVE_CALL');

        // hide button
        setIsThereIsIncomingCall(false);

        // show call button
        targetUserAction = 'Panggilan Tidak Dijawab';
      } else if (msg.action === 'ENDED') {
        // reset status
        setCallFromID(0);
        setCallToID(0);
        setRtcToken('');
        setCallStatus('LEAVE_CALL');

        // hide button
        setIsThereIsIncomingCall(false);

        // show call button
        targetUserAction = 'Panggilan Diakhiri';
      }

      setLabelStatus(targetUserAction);
    });

    socketCall.on('connect_error', (err) => {
      console.log(`websocket connect_error due to ${err.message}`);
    });
  }, []);

  // untuk proses publish / leave channel
  useEffect(() => {
    const joinAgoraCall = async () => {
      await agoraEngine
        .join(
          agoraOptions.appId,
          agoraOptions.channel,
          agoraOptions.token,
          agoraOptions.uid
        )
        .then((uid) => {
          setUid(uid);
        });

      const localAudioTrack = await await AgoraRTC.createMicrophoneAudioTrack({
        AEC: true,
        ANS: true,
      });
      setAgoraChannelParams((opts) => ({
        ...opts,
        localAudioTrack,
      }));

      await agoraEngine.publish(localAudioTrack);
    };

    const leaveAgoraCall = async () => {
      if (agoraChannelParams.localAudioTrack !== undefined) {
        agoraChannelParams.localAudioTrack.close();
        await agoraEngine.leave();
        setAgoraChannelParams({});
      }
    };

    if (callStatus !== '') {
      if (callStatus === 'ANSWER_CALL') {
        joinAgoraCall();
        setCallStatus('');
      } else if (callStatus === 'LEAVE_CALL') {
        if (agoraChannelParams.localAudioTrack !== undefined) {
          leaveAgoraCall();
          setCallStatus('');
        }
      }
    }
  }, [callStatus, agoraEngine, agoraOptions, agoraChannelParams]);

  agoraEngine.on('user-published', async (user, mediaType) => {
    await agoraEngine.subscribe(user, mediaType);
    // Subscribe and play the remote audio track.
    if (mediaType === 'audio') {
      const remoteAudioTrack = user.audioTrack;
      setAgoraChannelParams((opts) => ({
        ...opts,
        remoteUid: user.uid,
        remoteAudioTrack,
      }));
      remoteAudioTrack.play();
    }

    // Listen for the "user-unpublished" event.
    agoraEngine.on('user-unpublished', () => {
      // reset status
      setCallFromID(0);
      setCallToID(0);
      setRtcToken('');
      setCallStatus('LEAVE_CALL');

      // hide button
      setIsThereIsIncomingCall(false);
      setLabelStatus('');
    });
  });

  const answerCall = () => {
    setIsThereIsIncomingCall(false);
    socketCall.emit('incomingCallAction', {
      rtc_token: rtcToken,
      call_from: callFromID,
      call_to: callToID,
      action: 'ANSWERED', // constant status (all uppercase)
    });
  };

  const rejectCall = () => {
    setIsThereIsIncomingCall(false);
    socketCall.emit('incomingCallAction', {
      rtc_token: rtcToken,
      call_from: callFromID,
      call_to: callToID,
      action: 'REJECTED', // constant status (all uppercase)
    });
  };

  const endCall = () => {
    socketCall.emit('incomingCallAction', {
      rtc_token: rtcToken,
      call_from: callFromID,
      call_to: callToID,
      action: 'ENDED', // constant status (all uppercase)
      call_duration: 278,
    });
  };

  return {
    labelStatus,
    isThereIsIncomingCall,
    dataUSerCall,
    isConnected,
    uid,

    answerCall,
    rejectCall,
    endCall,
  };
};

export default SocketVoiceCall;
