import React, { lazy } from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import RootTemplate from 'components/template';
import AuthTemplate from 'components/page/Auth/Layout';

// s.Page Error
import NotFound from 'components/page/ErrorPage/404';
import UnAccess from 'components/page/ErrorPage/Unaccess';
// e.Page Error

// s.Auth
const Login = lazy(() => import('components/page/Auth/Login/index'));
const ForgotPassword = lazy(() =>
  import('components/page/Auth/ForgotPassword')
);
const SuccessResetPassword = lazy(() =>
  import('components/page/Auth/ForgotPassword/SuccessSendResetPassword')
);
const NewPassword = lazy(() =>
  import('components/page/Auth/ForgotPassword/NewPassword')
);
// e.Auth

// s.Finance
const DashboardFinance = lazy(() =>
  import('components/page/Finance/Dashboard')
);
// const WilayahFinance = lazy(() => import('components/page/Finance/Wilayah'));
const BankFinance = lazy(() => import('components/page/Finance/Bank'));
const RekeningTalasiFinance = lazy(() =>
  import('components/page/Finance/RekeningTalasi')
);
const PetaniFinance = lazy(() => import('components/page/Finance/Petani'));
const ProfilFinance = lazy(() => import('components/page/Finance/Pengaturan'));
const UpdateStatusFinance = lazy(() =>
  import('components/page/Finance/UpdateStatus')
);
const DetailUpdateStatusFinance = lazy(() =>
  import('components/page/Finance/UpdateStatus/DetailUpdateStatus')
);
const PembayaranFinpointFinance = lazy(() =>
  import('components/page/Finance/PembayaranFinpoint')
);
// e.Finance

// s.CFO
const DashboardCFO = lazy(() => import('components/page/CFO/Dashboard'));
const HargaPasarCFO = lazy(() => import('components/page/CFO/HargaPasar'));
const DetailHargaPasarCFO = lazy(() =>
  import('components/page/CFO/HargaPasar/DetailHargaPasar')
);
const DetailHargaPasarPersetujuanCFO = lazy(() =>
  import('components/page/CFO/HargaPasar/DetailHargaPasarPersetujuan')
);
const DetailHargaPasarRiwayatCFO = lazy(() =>
  import('components/page/CFO/HargaPasar/DetailHargaPasarRiwayat')
);
const PenawaranCFO = lazy(() => import('components/page/CFO/Penawaran'));
const DetailPenawaranCFO = lazy(() =>
  import('components/page/CFO/Penawaran/DetailPenawaran')
);
const HargaKhususCFO = lazy(() => import('components/page/CFO/HargaKhusus'));
const DetailHargaKhususCFO = lazy(() =>
  import('components/page/CFO/HargaKhusus/DetailHargaKhusus')
);
const PengaturanProfileCFO = lazy(() =>
  import('components/page/CFO/Pengaturan')
);
// e.CFO

// s.Purchasing
const ChatPurchasing = lazy(() => import('components/page/Purchasing/Chat'));
const DashboardPurchasing = lazy(() =>
  import('components/page/Purchasing/Dashboard')
);
const WilayahPurchasing = lazy(() =>
  import('components/page/Purchasing/Wilayah')
);
const WilayahFinance = lazy(() => import('components/page/Finance/Wilayah/'));
const DetailTambahWilayahPurchasing = lazy(() =>
  import('components/page/Purchasing/Wilayah/DetailTambahWilayah')
);
const DetailTambahWilayahFinance = lazy(() =>
  import('components/page/Finance/Wilayah/DetailTambahWilayah')
);
const WarehousePurchasing = lazy(() =>
  import('components/page/Purchasing/Warehouse')
);
const TambahWarehousePurchasing = lazy(() =>
  import('components/page/Purchasing/Warehouse/TambahWarehouse')
);
const AlasanPembatalanPurchasing = lazy(() =>
  import('components/page/Purchasing/AlasanPembatalan')
);
const DetailAlasanPembatalanPurchasing = lazy(() =>
  import('components/page/Purchasing/AlasanPembatalan/DetailAlasanPembatalan')
);
const TambahAlasanPembatalanPurchasing = lazy(() =>
  import('components/page/Purchasing/AlasanPembatalan/TambahAlasanPembatalan')
);
const EditAlasanPembatalanPurchasing = lazy(() =>
  import('components/page/Purchasing/AlasanPembatalan/EditAlasanPembatalan')
);
const KarakteristikKualitasPurchasing = lazy(() =>
  import('components/page/Purchasing/Karakteristik')
);
const DeatilKarakteristikKualitasPurchasing = lazy(() =>
  import('components/page/Purchasing/Karakteristik/DetailKarakteristik')
);
const GradePurchasing = lazy(() => import('components/page/Purchasing/Grade'));
const DetailGradePurchasing = lazy(() =>
  import('components/page/Purchasing/Grade/DetailGrade')
);
const KomoditiPurchasing = lazy(() =>
  import('components/page/Purchasing/Komoditi')
);
const DetailKomoditiPurchasing = lazy(() =>
  import('components/page/Purchasing/Komoditi/DetailKomoditi')
);
const TambahKomoditiPurchasing = lazy(() =>
  import('components/page/Purchasing/Komoditi/TambahKomiditi')
);
const EditKomoditiPurchasing = lazy(() =>
  import('components/page/Purchasing/Komoditi/Edit')
);
const SurveyorPurchasing = lazy(() =>
  import('components/page/Purchasing/Surveyor')
);
const HargaPasarPurchasing = lazy(() =>
  import('components/page/Purchasing/HargaPasar')
);
const DetailHargaSekarangPurchasing = lazy(() =>
  import('components/page/Purchasing/HargaPasar/DetailHargaSekarang')
);
const DetailHargaSekarangRiwayatPurchasing = lazy(() =>
  import('components/page/Purchasing/HargaPasar/DetailHargaSekarangRiwayat')
);
const DetailRiwayatUsulanPurchasing = lazy(() =>
  import('components/page/Purchasing/HargaPasar/DetailRiwayatUsulan')
);
const PusatBantuanPurchasing = lazy(() =>
  import('components/page/Purchasing/PusatBantuan')
);
const TambahPusatBantuanPurchasing = lazy(() =>
  import('components/page/Purchasing/PusatBantuan/TambahPusatBantuan')
);
const UbahPusatBantuanPurchasing = lazy(() =>
  import('components/page/Purchasing/PusatBantuan/UbahPusatBantuan')
);
const HargaKhususPurchasing = lazy(() =>
  import('components/page/Purchasing/HargaKhusus')
);
const TambahHargaKhususPurchasing = lazy(() =>
  import('components/page/Purchasing/HargaKhusus/TambahHargaKhusus')
);
const EditHargaKhususPurchasing = lazy(() =>
  import('components/page/Purchasing/HargaKhusus/EditHargaKhusus')
);

const DetailHargaKhususPurchasing = lazy(() =>
  import('components/page/Purchasing/HargaKhusus/DetailHargaKhusus')
);
const PersetujuanPurchasing = lazy(() =>
  import('components/page/Purchasing/Persetujuan')
);

const PersetujuanPurchasingDetail = lazy(() =>
  import('components/page/Purchasing/Persetujuan/Detail')
);
const UpdateStatusPurchasing = lazy(() =>
  import('components/page/Purchasing/UpdateStatus')
);
const UbahPurcashing = lazy(() => import('components/page/Purchasing/Ubah'));
const UbahPurcashingDetail = lazy(() =>
  import('components/page/Purchasing/Ubah/Detail')
);
const PengaturanProfilePurchasing = lazy(() =>
  import('components/page/Purchasing/Pengaturan')
);
// e.Purchasing

// s.Admin
const DashboardAdmin = lazy(() => import('components/page/Admin/Dashboard'));
const ParameterSistemAdmin = lazy(() =>
  import('components/page/Admin/ParameterSistem')
);
const MobileAppVersion = lazy(() =>
  import('components/page/Admin/MobileAppVersion')
);
const PesanKesalahanAdmin = lazy(() =>
  import('components/page/Admin/PesanKesalahan')
);
const MenuAdmin = lazy(() => import('components/page/Admin/Menu'));
const MenuTreeAdmin = lazy(() => import('components/page/Admin/Menu/MenuTree'));
const RoleAdmin = lazy(() => import('components/page/Admin/Role'));
const SettingRoleAdmin = lazy(() =>
  import('components/page/Admin/Role/SettingRole')
);
const UserAdmin = lazy(() => import('components/page/Admin/User'));
const AudittrailAdmin = lazy(() => import('components/page/Admin/Audittrail'));
const ModulAdmin = lazy(() => import('components/page/Admin/Modul'));
const PengaturanProfileAdmin = lazy(() =>
  import('components/page/Admin/Pengaturan')
);
// e.Admin

export default function Routes () {
  return (
    <Switch>

      <Route element={<AuthTemplate />}>
        <Route element={<Navigate replace to="login" />} index />
        <Route element={<Login />} path="login" index />
        <Route element={<ForgotPassword />} path="forgot-password" />
        <Route element={<NewPassword />} path="verification-password/:param" />
        <Route
          element={<SuccessResetPassword />}
          path="success-change-password"
        />
        {/* <Route element={<NewPassword />} path="new-password" /> */}
      </Route>

      <Route element={<RootTemplate />}>
        <Route element={<Navigate replace to="login" />} index />

        {/* s.Finance Route */}
        <Route exact path="/" element={<ProtectedRoute allowedRoles={2} />}>
          {/* implement dinamis path */}
          {/* {sidebarMenu?.map((route) => {
            if (route?.childs.length > 0) {
              return route?.childs?.map((child) => {
                const newDataRouteChild = newRoute?.find(e => e.id === child.id);
                console.log(route.url + child?.url);

                return (<Route key={child.id} element={newDataRouteChild?.element} path={route.url + child?.url}/>);
              });
            }
            const newDataRoute = newRoute?.find(e => e.id === route.id);

            return (<Route key={route.id} element={newDataRoute?.element} path={route?.url}/>);
          })} */}
          <Route element={<DashboardFinance />} path="dashboard/finance" />
          <Route
            element={<WilayahFinance />}
            path="administrasi/finance/wilayah"
          />
          <Route
            element={<DetailTambahWilayahFinance />}
            path="administrasi/finance/wilayah/detail/:id"
          />
          <Route element={<BankFinance />} path="administrasi/finance/bank" />
          <Route
            element={<RekeningTalasiFinance />}
            path="administrasi/finance/rekening-talasi"
          />
          <Route
            element={<PetaniFinance />}
            path="administrasi/finance/petani"
          />
          <Route
            element={<ProfilFinance />}
            path="administrasi/finance/pengaturan/profil"
          />
          <Route
            element={<UpdateStatusFinance />}
            path="penawaran/finance/update-status"
          />
          <Route
            element={<DetailUpdateStatusFinance />}
            path="penawaran/finance/update-status/:id"
          />
          <Route
            element={<PembayaranFinpointFinance />}
            path="penawaran/finance/pembayaran-finpoint"
          />
        </Route>
        {/* e.Finance Route */}

        {/* s.CFO Route */}
        <Route exact path="/" element={<ProtectedRoute allowedRoles={4} />}>
          <Route element={<DashboardCFO />} path="dashboard/cfo" />
          <Route
            element={<HargaPasarCFO />}
            path="persetujuan/cfo/harga-pasar"
          />

          <Route
            element={<DetailHargaPasarCFO />}
            path="persetujuan/cfo/harga-pasar/detail/:id"
          />
          <Route
            element={<DetailHargaPasarRiwayatCFO />}
            path="persetujuan/cfo/harga-pasar/riwayat/detail/:id"
          />
          <Route
            element={<DetailHargaPasarPersetujuanCFO />}
            path="persetujuan/cfo/harga-pasar/persetujuan/detail/:id"
          />

          <Route element={<PenawaranCFO />} path="persetujuan/cfo/penawaran" />
          <Route
            element={<DetailPenawaranCFO />}
            path="persetujuan/cfo/penawaran/:id"
          />
          <Route
            element={<HargaKhususCFO />}
            path="persetujuan/cfo/harga-khusus"
          />
          <Route
            element={<DetailHargaKhususCFO />}
            path="persetujuan/cfo/harga-khusus/detail/:id"
          />
          <Route
            element={<PengaturanProfileCFO />}
            path="pengaturan/cfo/profil"
          />
        </Route>
        {/* e.CFO Route  */}

        {/* s.Purchasing Route */}
        <Route exact path="/" element={<ProtectedRoute allowedRoles={3} />}>
          <Route
            element={<DashboardPurchasing />}
            path="dashboard/purchasing"
          />
          <Route element={<ChatPurchasing />} path="chat/purchasing" />
          <Route
            element={<WilayahPurchasing />}
            path="master-data/purchasing/wilayah"
          />
          <Route
            element={<DetailTambahWilayahPurchasing />}
            path="master-data/purchasing/wilayah/detail/:id"
          />
          <Route
            element={<WarehousePurchasing />}
            path="master-data/purchasing/warehouse"
          />
          <Route
            element={<TambahWarehousePurchasing />}
            path="master-data/purchasing/warehouse/:params"
          />
          <Route
            element={<AlasanPembatalanPurchasing />}
            path="master-data/purchasing/alasan-pembatalan"
          />
          <Route
            element={<DetailAlasanPembatalanPurchasing />}
            path="master-data/purchasing/alasan-pembatalan/detail/:id"
          />
          <Route
            element={<TambahAlasanPembatalanPurchasing />}
            path="master-data/purchasing/alasan-pembatalan/tambah"
          />
          <Route
            element={<EditAlasanPembatalanPurchasing />}
            path="master-data/purchasing/alasan-pembatalan/edit/:id"
          />
          <Route
            element={<KarakteristikKualitasPurchasing />}
            path="master-data/purchasing/karakteristik-kualitas"
          />
          <Route
            element={<DeatilKarakteristikKualitasPurchasing />}
            path="master-data/purchasing/karakteristik-kualitas/detail/:id"
          />
          <Route
            element={<GradePurchasing />}
            path="master-data/purchasing/grade"
          />
          <Route
            element={<DetailGradePurchasing />}
            path="master-data/purchasing/grade/detail/:id"
          />
          <Route
            element={<KomoditiPurchasing />}
            path="master-data/purchasing/komoditi"
          />
          <Route
            element={<EditKomoditiPurchasing />}
            path="master-data/purchasing/komoditi/edit/:id"
          />
          <Route
            element={<EditKomoditiPurchasing />}
            path="master-data/purchasing/komoditi/edit/:id"
          />
          <Route
            element={<DetailKomoditiPurchasing />}
            path="master-data/purchasing/komoditi/detail/:id"
          />
          <Route
            element={<TambahKomoditiPurchasing />}
            path="master-data/purchasing/komoditi/tambah"
          />
          <Route
            element={<SurveyorPurchasing />}
            path="master-data/purchasing/surveyor"
          />
          <Route
            element={<HargaPasarPurchasing />}
            path="master-data/purchasing/harga-pasar"
          />
          <Route
            element={<DetailHargaSekarangPurchasing />}
            path="master-data/purchasing/harga-pasar/:id"
          />
          <Route
            element={<DetailHargaSekarangRiwayatPurchasing />}
            path="master-data/purchasing/harga-pasar/riwayat/:id"
          />
          <Route
            element={<DetailRiwayatUsulanPurchasing />}
            path="master-data/purchasing/harga-pasar/riwayat-usulan/:id"
          />
          <Route
            element={<PusatBantuanPurchasing />}
            path="master-data/purchasing/pusat-bantuan"
          />
          <Route
            element={<TambahPusatBantuanPurchasing />}
            path="master-data/purchasing/pusat-bantuan/tambah"
          />
          <Route
            element={<UbahPusatBantuanPurchasing />}
            path="master-data/purchasing/pusat-bantuan/ubah/:id"
          />
          <Route
            element={<HargaKhususPurchasing />}
            path="penawaran/purchasing/harga-khusus"
          />
          <Route
            element={<TambahHargaKhususPurchasing />}
            path="penawaran/purchasing/harga-khusus/tambah"
          />
          <Route
            element={<EditHargaKhususPurchasing />}
            path="penawaran/purchasing/harga-khusus/:id"
          />
          <Route
            element={<DetailHargaKhususPurchasing />}
            path="penawaran/purchasing/harga-khusus/detail/:id"
          />
          <Route
            element={<PersetujuanPurchasing />}
            path="penawaran/purchasing/persetujuan"
          />
          <Route
            element={<PersetujuanPurchasingDetail />}
            path="penawaran/purchasing/persetujuan/:id"
          />
          <Route
            element={<UpdateStatusPurchasing />}
            path="penawaran/purchasing/update-status"
          />
          <Route
            element={<UbahPurcashing />}
            path="penawaran/purchasing/ubah"
          />
          <Route
            element={<UbahPurcashingDetail />}
            path="penawaran/purchasing/ubah/:id"
          />
          <Route
            element={<PengaturanProfilePurchasing />}
            path="pengaturan/purchasing/profil"
          />
        </Route>
        {/* e.Purchasing Route  */}

        {/* s.Admin Route */}

        <Route exact path="/" element={<ProtectedRoute allowedRoles={1} />}>
          <Route exact path="dashboard/admin" element={<DashboardAdmin />} />
          <Route
            element={<MobileAppVersion />}
            path="administrasi/admin/mobile-app-version"
          />
          <Route
            element={<ParameterSistemAdmin />}
            path="administrasi/admin/parameter-sistem"
          />
          <Route
            element={<PesanKesalahanAdmin />}
            path="administrasi/admin/pesan-kesalahan"
          />
          <Route element={<MenuAdmin />} path="administrasi/admin/menu" />
          <Route
            element={<MenuTreeAdmin />}
            path="administrasi/admin/menu/tree/:id"
          />
          <Route element={<RoleAdmin />} path="administrasi/admin/role" />
          <Route
            element={<SettingRoleAdmin />}
            path="administrasi/admin/role/setting/:id"
          />
          <Route element={<UserAdmin />} path="administrasi/admin/user" />
          <Route
            element={<AudittrailAdmin />}
            path="administrasi/admin/audittrail"
          />
          <Route element={<ModulAdmin />} path="master-aplikasi/admin/modul" />
          <Route
            element={<PengaturanProfileAdmin />}
            path="pengaturan/admin/profil"
          />
        </Route>
        {/* e.Admin Route */}

        {/* s.Error Page  */}
        <Route path="/unauthorized" element={<UnAccess />} />
        <Route path="*" element={<NotFound />} />
        {/* e.Error Page  */}
      </Route>
    </Switch>
  );
}
