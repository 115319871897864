export const objectToParams = (obj) => {
  if (!obj) return '';
  const str = Object.keys(obj)
    .filter((key) => obj[key])
    .map(function (key) {
      return key + '=' + obj[key];
    })
    .join('&');
  return str;
};

const translationDictionary = {
  po_number: 'Nomor PO',
  name: 'Nama',
  first_name: 'Nama Depan',
  last_name: 'Nama Belakang',
  email: 'Email',
  roles_id: 'Role',
  phone: 'No Handphone',
  reason: 'Alasan',
  comment: 'Alasan',
  reject_reason: 'Alasan',
  date_of_birth: 'Tanggal Lahir',
  nik: 'NIK',
  telp: 'No. Telpon',
  old_password: 'Password Lama',
  new_password: 'Password Baru',
  confirm_new_password: 'Konfirmasi Password',
  province_ids: 'Wilayah',
  bank_id: 'Bank',
  bank_account_name: 'Nama Pemilik Rekening',
  bank_account_number: 'Nomor Rekening',
  po_attachment: 'Lampiran PO',
  payment_date: 'Tanggal Pembayaran',
  invoice_number: 'Nomor Invoice',
  payment_voucher_number: 'Nomor Payment Voucher',
  question: 'Pertanyaan',
  quality_characteristic: 'Karakteristik Kualitas',
  indicator: 'Tingkat Kualitas',
  description: 'Deskripsi',
  city_ids: 'Kota',
  bid_correction: 'Harga Koreksi',
  bid_request: 'Harga Koreksi',
  surveyor_user_id: 'Surveyor',
  survey_date_time: 'Tanggal & Waktu Survey',
  commoditie_id: 'Komoditi',
  warehouse_ids: 'Warehouse'
};

export const ValidationPayload = (obj) => {
  let Errors = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]?.length === 0 || !obj[key]) {
      const translatedKey = translationDictionary[key] || key;
      Errors = { ...Errors, [key]: `${translatedKey} tidak boleh kosong!` };
    }
  });
  return { Errors, isValid: Object.keys(Errors).length === 0 };
};

export const formatDate = (date) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  return new Intl.DateTimeFormat('id-ID', options)
    .format(new Date(date))
    .replace('pukul', '')
    .trim();
};

export const formatYear = (date) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('id-ID', options)
    .format(new Date(date))
    .replace('pukul', '')
    .trim();
};

export const formatAsRupiah = (number) => {
  const formatRupiah = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatRupiah.format(number);
};

export const formatDateTimeToTime = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);
  const time = dateTime.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  return time;
};

export const formatDateChat = (dateString) => {
  const today = new Date();
  const date = new Date(dateString);

  if (isSameDay(date, today)) {
    // Display time if the date is today
    return formatTime(date);
  } else if (isSameDay(date, getYesterday())) {
    // Display "Yesterday" plus time if the date is yesterday
    return `Kemarin ${formatTime(date)}`;
  } else {
    // Display full date for other dates
    return formatDateOnly(date);
  }
};

const isSameDay = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const getYesterday = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

const formatTime = (date) => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

const formatDateOnly = (date) => {
  return date.toLocaleDateString();
};

export const DateDisplay = ({ date }) => {
  return <div>{formatDateChat(date)}</div>;
};

export const NumberFormatter = (number, currency) => {
  if (!number) {
    return 0;
  }
  let rupiah = '';
  const posNeg = number < 0 ? '- ' : '';
  const nominal = Math.abs(number);

  const nominalRef = nominal?.toString().split('').reverse().join('');
  for (let i = 0; i < nominalRef.length; i++) {
    if (i % 3 === 0) {
      rupiah += `${nominalRef.substr(i, 3)}.`;
    }
  }

  if (currency) {
    currency = currency.replace(/\s/g, ' ');
    return (
      posNeg +
      currency +
      rupiah
        .split('', rupiah.length - 1)
        .reverse()
        .join('')
    );
  }
  return (
    posNeg +
    rupiah
      .split('', rupiah.length - 1)
      .reverse()
      .join('')
  );
};

export function delimiterFormat(n, separator = ',') {
  var parts = n?.toString().split('.');
  return (
    parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
    (parts[1] ? ',' + parts[1] : '')
  );
}
