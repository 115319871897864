import React from 'react';
import { toast } from 'react-toastify';

const CustomToast = ({ user, message, icon, type }) => {
  const deleteToast = () => {
    toast.dismiss(); // Dismiss the current toast when delete icon is clicked
  };

  return (
    <div className={`custom-toast custom-toast-${type}`}>
        <div className='w-full flex flex-wrap gap-2 py-2'>
            <div className='w-1/5'>
                <div className={`${type === 'error' ? 'bg-white' : 'bg-blue-500'} flex justify-center items-center rounded-md py-3`}>
                    {icon}
                </div>
            </div>
            <div className='w-3/4'>
                <span className='font-bold text-lg'>{user}</span>
                {message.length > 70
                  ? <span className='block'>{message.substring(0, 65) + '... '}</span>
                  : <span className='block'>{message === 'file|file' ? 'Mengirim file' : message === 'file|attachment' ? 'Mengirim lampiran' : message}</span>
                }
            </div>

        </div>
      <span className="delete-icon" onClick={deleteToast}>
        {/* Tes custome toast */}
      </span>
    </div>
  );
};

export default CustomToast;
