import { Axios } from 'helper/Axios';

export const getNotifications = async (filter) => {
  try {
    const URL = 'notifications?' + filter;
    const response = await Axios.get(URL);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
};

export const readNotifications = async (id) => {
  try {
    const URL = `notifications/mark-read/${id}`;
    const response = await Axios.put(URL);
    return response.data.data;
  } catch (error) {
    // console.log(error);
  }
};
