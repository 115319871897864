import React from 'react';

const ModalCall = ({ isOpen, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="fixed inset-0 flex items-center justify-center">
        <div className="bg-white rounded-lg p-8 w-full h-screen w-screen-lg">
          {/* Modal Content */}
          <div className="flex flex-col h-full justify-center">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalCall;
