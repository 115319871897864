import React from 'react';
import { Modal, Button, Text } from 'react-web-skeleton';
import { useNavigate } from 'react-router-dom';

export default function ModalHakAkses ({
  title = '401',
  description = 'Anda tidak memiliki akses menu yang anda tuju!',
  isShow,
  handleIsShow,
}) {
  const navigate = useNavigate();

  const handleConfirm = () => {
    handleIsShow();
    navigate(-1);
  };
  return (
    <Modal
      isShow={isShow}
      modalContentStyle={{
        borderRadius: '8px',
      }}
      handleIsShow={handleIsShow}
    >
      <div className="flex flex-column gap-[32px]">
        <div className="Modal-Header">
          <Text size="l" textColor="#121926" weight="semibold">
            {title}
          </Text>
        </div>
        <div className="Modal-Body">{description}</div>
        <div className="modal-footer">
          <Button
            borderRadius="8px"
            id="myBtn"
            label="Ok"
            onClick={handleConfirm}
            state="default"
            textColor="white"
            type="primary"
            mode="danger"
          />
        </div>
      </div>
    </Modal>
  );
}
