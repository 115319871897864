import React from 'react';

const PaginationNotifications = ({ filter, totalItems, setFilter }) => {
  const totalPages = Math.ceil(totalItems);
  return (
    <>
      <div className="flex space-x-2">
        <button
          // Disable the "Previous" button if on the first page
          disabled={filter.page === 1}
          // Handle the click event to go to the previous page
          onClick={() => setFilter({ ...filter, page: filter.page - 1 })}
          className={`flex items-center space-x-2 p-2 border rounded-lg ${
            filter.page === 1
              ? 'bg-gray-100 text-gray-500'
              : 'hover:bg-blue-900 hover:text-white'
          } transition-colors duration-300 focus:outline-none focus:ring focus:ring-blue-300`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M11.293 4.293a1 1 0 011.414 1.414L8.414 10l4.293 4.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 01.293-.293z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="hidden md:block">Previous</span>
        </button>
        <button
          // Disable the "Next" button if on the last page
          disabled={filter.page === totalPages}
          // Handle the click event to go to the next page
          onClick={() => setFilter({ ...filter, page: filter.page + 1 })}
          className={`flex items-center space-x-2 p-2 border rounded-lg ${
            filter.page === totalPages
              ? 'bg-gray-100 text-gray-500'
              : 'hover:bg-blue-900 hover:text-white'
          } transition-colors duration-300 focus:outline-none focus:ring focus:ring-blue-300`}
        >
          <span class="hidden md:block">Next</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            ></path>
          </svg>
        </button>
      </div>

      {/* Page information */}
      <p className="text-gray-600">
        Page {filter.page ?? '-'} of {totalPages ?? '-'}
      </p>
    </>
  );
};

export default PaginationNotifications;
