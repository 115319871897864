import { Axios } from 'helper/Axios';

export const getListCHat = async () => {
  try {
    const URL = 'chat';
    const response = await Axios.get(URL);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
};

export const getListCHatHistory = async () => {
  try {
    const URL = '/chat/history?page=1&limit=50';
    const response = await Axios.get(URL);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
};

export const getTakeOver = async (idRoom) => {
  try {
    const URL = `chat/take-over/${idRoom}`;
    const response = await Axios.get(URL);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
};

export const getEndChat = async (idRoom) => {
  try {
    const URL = `chat/finish/${idRoom}`;
    const response = await Axios.get(URL);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
};

export const getListPenawaran = async (filter) => {
  try {
    const URL = `offer/chat?${filter}`;
    const response = await Axios.get(URL);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
};

export const getDetailListPenawaran = async (offerId) => {
  try {
    const URL = `offer/${offerId}`;
    const response = await Axios.get(URL);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
};

export const getListHargaKhusus = async (filter) => {
  try {
    const URL = `special-price?${filter}`;
    const response = await Axios.get(URL);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
};

export const getDetailListHargaKhusus = async (couponId) => {
  try {
    const URL = `special-price/${couponId}`;
    const response = await Axios.get(URL);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
};

export const readChat = async (idChatRoom) => {
  try {
    const URL = `chat/read-chat/${idChatRoom}`;
    const response = await Axios.get(URL);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
};

export const regiterToken = async (payload) => {
  try {
    const URL = '/register-fcm-token';
    const response = await Axios.post(URL, payload);
    return response.data;
  } catch (error) {
    // console.log(error);
  }
};
