import io from 'socket.io-client';
import { checkIdUser, checkAuthentication } from 'utils/authUtils';

let socket;
const token = checkAuthentication();
const idUser = checkIdUser();
const URL = 'wss://websocket.talasi.codr-staging.id/';

export const socketCall = io(URL, {
  transports: ['websocket'],
});

export const initialRefectSocket = () => {
  socket = io('wss://websocket.talasi.codr-staging.id');
  socket.on('connect', () => {
    socket.emit('joinMyChatRoom', { current_user_token: token });
  });
};

export const initiateSocket = (room, usernameSendUser) => {
  socket = io('wss://websocket.talasi.codr-staging.id');
  socket.on('connect', () => {
    // console.log("connect");

    // Saat terkoneksi maka akan request join room
    socket.emit('joinRoomFarmer', {
      user_id: parseInt(idUser),
      chat_room_id: parseInt(room),
      name: usernameSendUser,
    });

    // Setelah join room, wajib mengirimkan request empty message
    socket.emit('ChatInit', {
      user_id: parseInt(idUser),
      chat_room_id: parseInt(room),
      name: usernameSendUser,
      message: '',
    });
  });
};

export const disconnectSocket = () => {
  // console.log('Disconnecting socket...');
  if (socket) socket.disconnect();
};

export const subscribeToChat = (cb) => {
  if (!socket) return true;
  socket.on('reply-join', (msg) => {
    return cb(null, msg);
  });
};

export const subscribeToNewChat = (cb) => {
  if (!socket) return true;
  socket.on('reply-chat', (msg) => {
    return cb(null, msg);
  });
};

export const sendMessage = (room, usernameSendUser, message) => {
  if (socket) {
    socket.emit('ChatAdmin', {
      user_id: parseInt(idUser),
      chat_room_id: parseInt(room),
      name: usernameSendUser,
      message: message.contentMessage,
      offer_id: message.offerId,
      coupon_id: message.couponId,
    });
  }
};

export const setupIncomingChatListener = (callback) => {
  socket.on('incoming-chat', (msg) => {
    callback(msg);
  });
};
