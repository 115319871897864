import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthWrapper, Image } from 'react-web-skeleton';
import image from '../../../../assets/img/login/sideimage.png';

const AuthTemplate = () => {
  return (
    <>
      <AuthWrapper>
        <div className="flex">
          <div className="flex flex-wrap w-1/2">
            <Suspense fallback="loading...">
              <Outlet />
            </Suspense>
          </div>
          <div className="flex flex-wrap w-1/2">
            <Image className=" w-full object-cover" src={image} />
          </div>
        </div>
      </AuthWrapper>
    </>
  );
};

export default AuthTemplate;
