import React, { Suspense, useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { checkAuthentication } from 'utils/authUtils';
import NavbarPanel from 'components/template/Navbar';
import Sidebar from 'components/template/Sidebar';
import { LoadingScreen } from 'components/UI/common/atoms/Loading';
import ModalHakAkses from 'components/UI/common/molecules/ModalHakAkses';

const RootTemplate = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleExpandedSidebar = () => {
    setIsExpanded((prev) => !prev);
  };
  const [showModalAkses, setShowModalAkses] = useState(false);
  const sidebarMenu = JSON.parse(localStorage.getItem('sideMenu'));

  const routes = [];

  const routesMenu = sidebarMenu?.map((menu) => (
    {
      isSub: menu?.childs?.length > 0,
      icon: menu?.icon || '',
      path: menu?.url || '',
      label: menu?.name || '',
      childs: menu?.childs?.map((child) => ({
        path: child?.url || '',
        label: child?.name || '',
      }))
    }));
  routes.push(routesMenu);

  const isAuthenticated = checkAuthentication();
  const { pathname } = useLocation();
  const exceptionUrlFinance = '/administrasi/finance/pengaturan/profil';
  const exceptionUrlAdmin = '/pengaturan/admin/profil';
  const exceptionUrlCFO = '/pengaturan/cfo/profil';
  const exceptionUrlPurchasing = '/pengaturan/purchasing/profil';

  useEffect(() => {
    if (
      !sidebarMenu?.find((e) => e.url === pathname || (e.childs?.find((child) => pathname.includes(child.url)))) &&
      pathname !== exceptionUrlFinance && pathname !== exceptionUrlAdmin && pathname !== exceptionUrlCFO && pathname !== exceptionUrlPurchasing
    ) {
      if (isAuthenticated) {
        setShowModalAkses(true);
      }
    }
  }, [sidebarMenu, pathname]);

  return (
    <div className="flex h-screen ">
      <Sidebar
        routes={routes}
        logo="../Logo.png"
        isExpanded={isExpanded}
        handleExpandedSidebar={handleExpandedSidebar}
      />

      <div className="flex-1 flex flex-col overflow-hidden ">
        <NavbarPanel handleMenu={handleExpandedSidebar} />
        <Suspense fallback={<LoadingScreen />}>
          <div className="overflow-y-auto flex-1 ">
            <Outlet />
          </div>
        </Suspense>
      </div>

      <ModalHakAkses
        isShow={Boolean(showModalAkses)}
        handleIsShow={() => setShowModalAkses(!showModalAkses)}
      />
    </div>
  );
};

export default RootTemplate;
