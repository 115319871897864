import React from 'react';
import { Button as Btn } from 'react-web-skeleton';

const Button = ({
  borderRadius,
  label,
  mode,
  onClick,
  state,
  textColor,
  type,
  icon,
  width,
  className,
  size,
}) => {
  if (type === 'outline' || type === 'secondary') {
    return (
      <Btn
        borderRadius={borderRadius}
        mode={mode}
        icon={icon}
        type={type}
        label={label}
        onClick={onClick}
        state={state}
        width={width}
        size={size}
      />
    );
  }

  if (mode === 'primary') {
    return (
      <Btn
        className="bg-blue-900"
        borderRadius={borderRadius}
        mode={mode}
        icon={icon}
        type={type}
        label={label}
        onClick={onClick}
        state={state}
        textColor={textColor}
        width={width}
        size={size}
      />
    );
  }

  return (
    <Btn
      className={className}
      borderRadius={borderRadius}
      mode={mode}
      icon={icon}
      type={type}
      label={label}
      onClick={onClick}
      state={state}
      textColor={textColor}
      width={width}
      size={size}
    />
  );
};

export default Button;
