import CryptoJS from 'crypto-js';

export const checkAuthentication = () => {
  const keyToken = localStorage.getItem('token');

  if (keyToken) {
    const decryptedToken = CryptoJS.AES.decrypt(keyToken, 'key_token').toString(
      CryptoJS.enc.Utf8
    );

    return decryptedToken;
  }
};

export const checkRoleId = () => {
  const keyRoleId = localStorage.getItem('role_id');

  if (keyRoleId) {
    const decryptedRoleId = CryptoJS.AES.decrypt(
      keyRoleId,
      'key_roleId'
    ).toString(CryptoJS.enc.Utf8);

    return decryptedRoleId;
  }
};

export const checkIdUser = () => {
  const keyIdUser = localStorage.getItem('id_user');

  if (keyIdUser) {
    const decryptedIdUser = CryptoJS.AES.decrypt(
      keyIdUser,
      'key_idUser'
    ).toString(CryptoJS.enc.Utf8);

    return decryptedIdUser;
  }
};

export const clearLocalStorageData = () => {
  localStorage.clear();
};
