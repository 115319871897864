import React, { useState, useEffect } from 'react';
import './App.css';
import Page from 'routes';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from '@sentry/react';
import { getTokenFirebase, onMessageListener } from './config/firebase';
import CustomToast from 'components/UI/common/atoms/CustomToast';
import { MessageNotifIcon } from 'components/UI/common/atoms/Icons';
import { regiterToken } from 'utils/purchasing/chat';
import { useMutation } from 'react-query';
import { checkAuthentication } from 'utils/authUtils';
import { Icon } from 'react-web-skeleton';

function App () {
  const isAuthenticated = checkAuthentication();
  const [notifications, setNotifications] = useState({ title: '', body: '' });

  const { mutate } = useMutation({
    mutationFn: (token) => regiterToken({ token })
  });

  useEffect(async () => {
    const token = await getTokenFirebase();
    if (isAuthenticated) {
      if (token) {
        mutate(token);
      }
    }

    // Setelah token didapatkan, toket dapat dikirimkan di Backend untuk di simpan
  }, []);

  useEffect(() => {
    // Panggil method onMessageListener() di dalam useEffect
    onMessageListener().then(payload => {
      setNotifications({ title: payload.notification.title, body: payload.notification.body });
    }).catch(err => console.log('failed push : ', err));
  });

  useEffect(() => {
    notifications?.body && toast(<CustomToast user={notifications?.title} message={notifications?.body} icon={<MessageNotifIcon />} type="info" />);
  }, [notifications]);

  useEffect(() => {
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
    }

    if (Notification.permission === 'denied') {
      alert('Silahkan Aktifkan Notifikasi Browser Anda!');
      toast(<CustomToast
          user={'Aktifkan Notifikasi'}
          message={'Silahkan aktifkan notifikasi browser anda!'}
          icon={<Icon
            label="bell"
            mode="danger"
            onClick={function noRefCheck () {}}
            shape="square"
            size="medium"
            type="default"
          /> }
      type="error" />);
    }
  }, []);

  return (
    <div>
      <ToastContainer />
      <Page />
    </div>
  );
}

export default Sentry.withProfiler(App);
