import React from 'react';
import { AvatarView } from 'components/UI/common/atoms/AvatarView';
import Button from 'components/UI/common/atoms/Button';

const ToastCall = ({ receiveCall, answerCall, rejectCall, dataUSerCall }) => {
  const openModalCall = () => {
    if (typeof receiveCall === 'function') {
      receiveCall();
    }
  };

  const answerCalls = () => {
    if (typeof answerCall === 'function') {
      answerCall();
    }
  };

  const rejectCalls = () => {
    if (typeof rejectCall === 'function') {
      rejectCall();
    }
  };

  return (
    <div className="flex flex-wrap justify-between pt-3">
      <div className="w-1/2">
        <p className="text-sm">Panggilan Masuk</p>
        <p className="font-semibold text-lg">{dataUSerCall.call_from_name}</p>
      </div>
      <div className="w-1/2 flex items-center justify-end">
        <AvatarView
          image={dataUSerCall.from_user_photo}
          width={56}
          height={56}
        />
      </div>
      <div className="w-full flex flex-wrap text-center my-5">
        <div className="w-1/2 px-1">
          <Button
            borderRadius="8px"
            id="myBtn"
            label="Tolak"
            state="default"
            textColor="white"
            type="primary"
            mode="danger"
            className="w-full"
            onClick={rejectCalls}
          />
        </div>
        <div className="w-1/2 px-1">
          <Button
            borderRadius="8px"
            id="myBtn"
            label="Terima"
            state="default"
            textColor="white"
            type="primary"
            mode="success"
            className="w-full"
            onClick={() => {
              openModalCall();
              answerCalls();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ToastCall;
